import * as React from "react";

import Puzzle from "../../Puzzle";

import pamiatki from "../../images/pamiatki.jpg";
import map from "../../images/m4.png";
import audio from "../../../static/a4.mp3";

const Page = () => {
  return (
    <Puzzle
      title="Riddle 4"
      author="Author: Agata Grzych"
      imageSrc={pamiatki}
      imageAlt="kolorowe talerze wiszące na białej ścianie"
      mapSrc={map}
      mapAlt="Punkt zaznaczony na mapie"
      // audio={audio}
      language="en"
      languageUrl={"/zagadki/gP60VT1J7Z"}
    >
      <div>
        <p>
          The main function of the plates is to let us eat from them – and this
          is quite obvious. But the plates can also serve as decorative or
          commemorative artifacts.
        </p>
        <p>
          It is hard to define who, and most of all why, came up with such an
          idea. The idea, however, caught on. Such plates can be hand-painted or
          decorated with graphics and photographs. Plates with the image of the
          Pope were once quite popular. On the Internet you will find quite a
          lot of jubilee plates, in tourist towns you can buy plates with the
          most popular attractions of a given place. There is also a whole
          collection of wall-mounted ceramics straight from Bolesławiec or
          Ćmielów, which, in turn, is dominated by brown or blue floral
          patterns. The aforementioned decorative tableware elements can be seen
          or purchased in the oldest and largest antique shop in Wrocław, Daes
          on Kościuszko Square, which for many years operated under the
          legendary name Desa.
        </p>
        <p>
          The Cepelic style has been present in some Polish apartments for many
          years. Decorative plates are surprising and not entirely impractical,
          they may be even in a way perverse. Let's imagine that we are eating
          dinner on such a decorative plate and suddenly the Holy Mary of
          Częstochowa looks out from under a traditional Polish dish. The
          aesthetic and artistic values themselves are also a contentious issue,
          although nowadays many designs are back in favour.
        </p>
        <p>
          The earliest memories of my grandmother, who has always lived at KDM,
          are about decorative plates. The entire collection hung on the kitchen
          wall. Plates with blue flowers, brown ones, a clock made of a plate
          and the one with a painting of the Virgin Mary, placed in the living
          room above the pictures of the grandchildren. For as long as I can
          remember, they aroused my fascination, although for quite a long time
          I could not find any sense in hanging them on the wall or placing them
          vertically on a bookcase. Maybe they are not as ambiguous as images,
          more accessible?
        </p>
        <p>
          Souvenirs from KDM is a series of plates that tell the history of
          Kościuszko Housing District, but in a very personal way. They show it
          from the inside. It is a work presenting the experience of living in
          this area for several months, with the prospect of moving into another
          one soon – the result of staying in my grandmother's apartment, where
          little has changed for a few months after her death and, on the other
          hand, confronting myself with places that looked completely different
          a dozen years ago.
        </p>
      </div>
    </Puzzle>
  );
};

export default Page;
